import { ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import Navigation from "../Navigation";
import { Footer } from "../Footer";
import { routes } from "../Router/routes";

type LayoutProps = {
	children: ReactNode;
	title?: string;
};

const Layout = (props: LayoutProps) => {

	if (props.title === routes.PageNotFound.title) {
		return (
			<>
				<Helmet>
					<title>{props.title}</title>
				</Helmet>
				<main className="min-h-screen">{props.children}</main>
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>{props.title}</title>
			</Helmet>
			<Navigation routes={routes} />
			<main className="min-h-[calc(100dvh_-_105px_-_116px)]">{props.children}</main>
			<Footer />
		</>
	);
};

export default Layout;
