type MapValuesToKeysIfAllowed<T> = {
	[K in keyof T]: T[K] extends PropertyKey ? K : never;
};
type Filter<T> = MapValuesToKeysIfAllowed<T>[keyof T];

export function groupBy<T extends Record<PropertyKey, never>, Key extends Filter<T>>(
	arr: T[],
	key: Key,
): Record<T[Key], T[]> {
	return arr.reduce(
		(accumulator, val) => {
			const groupedKey = val[key];
			if (!accumulator[groupedKey]) {
				accumulator[groupedKey] = [];
			}
			accumulator[groupedKey].push(val);
			return accumulator;
		},
		{} as Record<T[Key], T[]>,
	);
}

export const customObjectValueFn = Object.values as <T, K extends keyof T>(obj: T) => Array<T[K]>;
export const customObjectKeyFn = Object.keys as <T, K extends keyof T>(obj: T) => Array<K>;
export const customObjectEntriesFn = Object.entries as <T, K extends keyof T>(obj: T) => Array<[K, T [K]]>;

