import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider } from "antd";
import { ErrorBoundary } from "react-error-boundary";
import Fallback from "./components/Error/Fallback";
import Router from "./components/Router";
import { theme } from "./utils";
import { HelmetProvider } from "react-helmet-async";
import NavigationProvider from "./components/Navigation/context/NavigationProvider";

function App() {
	const queryClient = new QueryClient({
		defaultOptions: { queries: { refetchOnWindowFocus: false } },
	});

	return (
		<ErrorBoundary FallbackComponent={Fallback}>
			<QueryClientProvider client={queryClient}>
				<ConfigProvider theme={theme}>
					<HelmetProvider>
						<NavigationProvider>
							<Router />
						</NavigationProvider>
					</HelmetProvider>
				</ConfigProvider>
			</QueryClientProvider>
		</ErrorBoundary>
	);
}

export default App;
