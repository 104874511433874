import { Link } from "react-router-dom";

const PageNotFound = () => {
	return (
		<div className="flex justify-center items-center h-[calc(100vh-106px)]">
			<div>
				<h1 className="mb-2 text-center">404 | Opsss...</h1>
				<div className="text-center text-lg">
					Forse stavi cercando <Link to={"/"} className="font-semibold">Home page</Link>
				</div>
			</div>
		</div>
	);
};

export default PageNotFound;
