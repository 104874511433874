import { RouteObject } from "react-router-dom";
import Home from "../../pages/Home";
import Marchi from "../../pages/brands";
import Contattaci from "../../pages/contacts";
import Prodotti from "../../pages/products";
import PageNotFound from "../../pages/404";
import ProductDetails from "../../pages/products/ProductsDetails";

export type RuotesProps = {
	[key: string]: RouteObject & {
		title: string;
		hide?: boolean;
		appBar?: { title: string };
	};
};

export const routes = {
	Home: {
		title: "Farmacia Corti",
		path: "/",
		Component: Home,
		appBar: { title: "Home" },
	},
	Products: {
		title: "Prodotti",
		path: "/prodotti",
		Component: Prodotti,
		appBar: { title: "Prodotti" },
	},
	ProductDetails: {
		title: "Prodotti",
		path: "/prodotti/:id",
		Component: ProductDetails,
	},
	Brands: {
		title: "Marchi",
		path: "/marchi",
		Component: Marchi,
		appBar: { title: "Marchi" },
	},
	Contacts: {
		title: "Contatti",
		path: "/contattaci",
		Component: Contattaci,
		appBar: { title: "Contatti" },
	},
	PageNotFound: {
		title: "Page not found",
		path: "*",
		Component: PageNotFound,
	},
} satisfies RuotesProps;
