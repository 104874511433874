import classNames from "classnames";

function format(num: number) {
	if (Number(num) === 0) {
		return "";
	}
	return (Math.round(num * 100) / 100).toFixed(2);
}

const Price = (props: {
	price: number;
	discountPrice?: number;
	size?: "small" | "normal" | "large";
}) => {
	const { size = "normal" } = props;


	return (
		<div className="flex gap-4 items-center">
			<p
				className={classNames({
					"text-lg md:text-xl": size === "normal",
					"text-base lg:!text-lg": props.discountPrice !== undefined && size === "normal",
					"line-through opacity-40": props.discountPrice !== undefined,
					"font-semibold": true,
				})}
			>
				€ {format(props.price)}
			</p>

			{props.discountPrice && (
				<p
					className={classNames({
						"text-lg md:text-xl": size === "normal",
						"font-semibold": true,
					})}
				>
					€ {format(props.discountPrice)}
				</p>
			)}
		</div>
	);
};

export default Price;