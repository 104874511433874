import { BrowserRouter, Route, Routes } from "react-router-dom";
import { customObjectValueFn } from "../../utils";
import Layout from "../Layout";
import { RuotesProps, routes } from "./routes";
import { Fragment } from "react/jsx-runtime";

const Router = () => {
	return (
		<BrowserRouter>
			<Routes>
				{customObjectValueFn<RuotesProps, keyof RuotesProps>(routes).map(({ path, Component, hide, title }, i) => (
					<Fragment key={i}>
						{hide ? (
							<></>
						) : (
							<Route
								path={path}
								element={
									<Layout title={title}>
										{Component ? <Component /> : <></>}
									</Layout>
								}
								key={i}
							/>
						)}
					</Fragment>
				))}
			</Routes>
		</BrowserRouter>
	);
};

export default Router;
