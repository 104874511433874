import axios from "axios";

const StrapiClient = axios.create({
	baseURL: import.meta.env.VITE_PUBLIC_STRAPI_API_URL,
	timeout: 12000,
	headers: {
		"Content-Type": "application/json",
		Authorization: `Bearer ${import.meta.env.VITE_PUBLIC_STRAPI_API_TOKEN}`,
	},
});

export { StrapiClient };

