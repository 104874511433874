import { AxiosResponse } from "axios";

export async function axiosExtract<T>(promise: Promise<AxiosResponse<T>>): Promise<T> {
	return promise
		.then((res) => res.data)
		.catch((error) => {
			throw new Error(error);
		});
}


export function unpromisify<T extends (...args: any[]) => Promise<void> | void>(
	fn: T,
): (...args: Parameters<T>) => void {
	return (...args) => {
		(async () => {
			await fn(...args);
		})().catch(console.warn);
	};
}